//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchMemberLevels, addMemberLevel, updateMemberLevel, deleteMemberLevel } from "@/api/member";
import Pagination from "@/components/Pagination";
import { validateMobile, isNum } from "@/utils/validate";
export default {
  name: "Memberlevel",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        keyword: "",
        mobile: ''
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      btnLoading: false,
      rules: {
        name: [{
          required: true,
          message: "等级名称必填",
          trigger: "blur"
        }],
        grade: [{
          required: true,
          validator: isNum,
          trigger: "blur"
        }],
        valid_date: [{
          required: true,
          validator: isNum,
          trigger: "blur"
        }],
        total_num: [{
          required: true,
          validator: isNum,
          trigger: "blur"
        }],
        max_power: [{
          required: true,
          validator: isNum,
          trigger: "blur"
        }],
        service_charge_discount: [{
          validator: isNum,
          trigger: "blur"
        }]
      },
      // member
      memberMap: {
        update: "编辑会员等级",
        create: "添加会员等级"
      },
      levelTitle: "",
      levelDialog: false,
      dialogForm: {
        name: "",
        grade: 0,
        billing_type: 'time',
        service_charge_discount: 100,
        is_show: '1'
      }
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchMemberLevels(data).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          _this2.btnLoading = true;
          addMemberLevel(_this2.dialogForm).then(function (res) {
            console.log(res);

            _this2.$message({
              type: "success",
              message: "添加成功"
            });

            _this2.levelDialog = false;
            _this2.btnLoading = false;

            _this2.getTableData();
          }).catch(function (error) {
            console.log(error);
            _this2.btnLoading = false;

            _this2.$message({
              type: "error",
              message: error.data["message"]
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate() {
      var _this3 = this;

      console.log("修改会员");
      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          console.log(_this3.dialogForm);
          _this3.btnLoading = true;
          updateMemberLevel(_this3.dialogForm).then(function (res) {
            console.log(res);

            _this3.$message({
              type: "success",
              message: "修改成功"
            });

            _this3.levelDialog = false;
            _this3.btnLoading = false;

            _this3.getTableData();
          }).catch(function (error) {
            console.log(error);
            _this3.btnLoading = false;

            _this3.$message({
              type: "error",
              message: error.data["message"]
            });
          });
        }
      });
    },
    resetLevel: function resetLevel() {
      this.dialogForm = {
        name: "",
        grade: 0,
        billing_type: 'time',
        service_charge_discount: 100,
        is_show: '1'
      };
    },
    handleAddBtn: function handleAddBtn() {
      this.resetLevel();
      this.levelTitle = "create";
      this.levelDialog = true;
    },
    handleEditBtn: function handleEditBtn(row) {
      this.dialogForm = Object.assign({}, row);
      this.dialogForm.is_show = this.dialogForm.is_show == 1 ? '1' : '0';
      this.levelTitle = "update";
      this.levelDialog = true;
    },
    handleDel: function handleDel(row) {
      var _this4 = this;

      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664\u8BE5\u4F1A\u5458\u7B49\u7EA7";
      var title = "删除会员等级";
      this.$confirm(content, title, {
        confirmButtonText: "删除等级",
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: "取消"
      }).then(function () {
        deleteMemberLevel(row).then(function (res) {
          console.log(res);

          _this4.$message({
            type: "success",
            message: "删除成功"
          });

          var index = _this4.dataList.indexOf(row);

          _this4.dataList.splice(index, 1);
        }).catch(function (err) {
          _this4.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    }
  }
};